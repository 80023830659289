//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-0:_7904,_6268,_7920,_7160,_1168,_9336,_908,_9612;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-0')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-0', "_7904,_6268,_7920,_7160,_1168,_9336,_908,_9612");
        }
      }catch (ex) {
        console.error(ex);
      }